import { useEffect } from "react";
import { create } from "zustand";

import type { Alert } from "~/constants/global.ts";

const NARROW_WIDTH = 480;
export function useIsNarrowViewSetup() {
	const setIsNarrowView = useUiStore((state) => {
		return state.setIsNarrowView;
	});

	useEffect(() => {
		function handleResize() {
			if (typeof window !== "undefined") {
				setIsNarrowView(window.innerWidth <= NARROW_WIDTH);
			}
		}
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			return window.removeEventListener("resize", handleResize);
		};
	}, [setIsNarrowView]);
}

export const useIsNarrowView = () => {
	return useUiStore((state) => {
		return state.isNarrowView;
	});
};

export const useIsLoadingNarrowView = () => {
	return useUiStore((state) => {
		return state.isLoadingNarrowView;
	});
};

export const useIsDialogOpen = () => {
	return useUiStore((state) => {
		return {
			isDialogOpen: state.isDialogOpen,
			setIsDialogOpen: state.setIsDialogOpen,
		};
	});
};

export const useIsAppLoading = () => {
	return useUiStore((state) => {
		return {
			isAppLoading: state.isAppLoading,
			setIsAppLoading: state.setIsAppLoading,
		};
	});
};

export const useClientAlert = () => {
	return useUiStore((state) => {
		return {
			clientAlert: state.clientAlert,
			setClientAlert: state.setClientAlert,
		};
	});
};

export const useUiStore = create<{
	isLoadingNarrowView: boolean;
	isNarrowView: boolean;
	// eslint-disable-next-line unused-imports/no-unused-vars
	setIsNarrowView: (isNarrowView: boolean) => void;
	isDialogOpen: boolean;
	// eslint-disable-next-line unused-imports/no-unused-vars
	setIsDialogOpen: (isDialogOpen: boolean) => void;
	isAppLoading: boolean;
	// eslint-disable-next-line unused-imports/no-unused-vars
	setIsAppLoading: (isAppLoading: boolean) => void;
	clientAlert: Alert | null;
	// eslint-disable-next-line unused-imports/no-unused-vars
	setClientAlert: (clientAlert: Alert | null) => void;
}>((set) => {
	return {
		isLoadingNarrowView: true,
		isNarrowView: false,
		setIsNarrowView: (isNarrowView) => {
			return set(() => {
				return { isNarrowView, isLoadingNarrowView: false };
			});
		},
		isDialogOpen: false,
		setIsDialogOpen: (isDialogOpen) => {
			return set(() => {
				return { isDialogOpen };
			});
		},
		isAppLoading: false,
		setIsAppLoading: (isAppLoading) => {
			return set(() => {
				return { isAppLoading };
			});
		},
		clientAlert: null,
		setClientAlert: (clientAlert) => {
			return set(() => {
				return { clientAlert };
			});
		},
	};
});
